// Generated by Framer (d894e1b)

import { addFonts, addPropertyControls, ControlType, cx, CycleVariantState, Image, useActiveVariantCallback, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";

const cycleOrder = ["m7TdykRJN", "J7T83zccY"];

const serializationHash = "framer-sTaNq"

const variantClassNames = {J7T83zccY: "framer-v-13xrkg0", m7TdykRJN: "framer-v-1frf5y5"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transitions = {default: {damping: 60, delay: 0, mass: 1, stiffness: 500, type: "spring"}};

const isSet = (value) => {
    return value !== undefined && value !== null && value !== "";
};


const toResponsiveImage = (value) => {
    if (typeof value === "object" && value !== null && typeof value.src === "string") {
        return value;
    };
    return typeof value === "string" ? {src: value} : undefined;
};


const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const humanReadableVariantMap = {Default: "m7TdykRJN", Selected: "J7T83zccY"}

const getProps = ({cover, height, id, tap, width, ...props}) => { return {...props, Hsz0D4R48: tap ?? props.Hsz0D4R48, variant: humanReadableVariantMap[props.variant] ?? props.variant ?? "m7TdykRJN", xRALwOhFA: cover ?? props.xRALwOhFA} }

const createLayoutDependency = (props, variants) => variants.join('-') + props.layoutDependency

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number;variant?: keyof typeof humanReadableVariantMap;cover?: {src: string; srcSet?: string};tap?: any; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale } = useLocaleInfo()

const {style, className, layoutId, variant, xRALwOhFA, Hsz0D4R48, ...restProps} = getProps(props)

const {baseVariant, classNames, gestureVariant, setGestureState, setVariant, transition, variants} = useVariantState({cycleOrder, defaultVariant: "m7TdykRJN", transitions, variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const { activeVariantCallback, delay } = useActiveVariantCallback(baseVariant)

const onTap1k3w8ya = activeVariantCallback(async (...args) => {
if (Hsz0D4R48) {
const res = await Hsz0D4R48(...args);
if (res === false) return false;
}
})

const ref1 = React.useRef<HTMLElement>(null)

const defaultLayoutId = React.useId()

const sharedStyleClassNames = []

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<motion.div initial={variant} animate={variants} onHoverStart={() => setGestureState({isHovered: true})} onHoverEnd={() => setGestureState({isHovered: false})} onTapStart={() => setGestureState({isPressed: true})} onTap={() => setGestureState({isPressed: false})} onTapCancel={() => setGestureState({isPressed: false})} className={cx("framer-sTaNq", ...sharedStyleClassNames, classNames)} style={{display: "contents"}}>
<Transition value={transition}><motion.div {...restProps} className={cx("framer-1frf5y5", className)} data-framer-name={"Default"} data-highlight layoutDependency={layoutDependency} layoutId={"m7TdykRJN"} onTap={onTap1k3w8ya} ref={ref ?? ref1} style={{"--border-bottom-width": "0px", "--border-color": "rgba(0, 0, 0, 0)", "--border-left-width": "0px", "--border-right-width": "0px", "--border-style": "solid", "--border-top-width": "0px", backgroundColor: "var(--token-58389c29-c015-4427-a57c-637e65cb6404, rgb(235, 235, 235))", borderBottomLeftRadius: 16, borderBottomRightRadius: 16, borderTopLeftRadius: 16, borderTopRightRadius: 16, ...style}} variants={{J7T83zccY: {"--border-bottom-width": "1px", "--border-color": "var(--token-e0da01bd-4e0f-4d83-9396-a2ca91f1ef19, rgb(0, 0, 0)) /* {\"name\":\"Heading\"} */", "--border-left-width": "1px", "--border-right-width": "1px", "--border-style": "solid", "--border-top-width": "1px"}}} {...addPropertyOverrides({J7T83zccY: {"data-border": true, "data-framer-name": "Selected"}}, baseVariant, gestureVariant)}>{isSet(xRALwOhFA) && (<Image background={{alt: "", fit: "fill", sizes: "max((min(200px, 100vw) - 6px) / 0, 0px)", ...toResponsiveImage(xRALwOhFA)}} className={"framer-10ps6sl"} data-framer-name={"Image"} layoutDependency={layoutDependency} layoutId={"OsxVtN0HT"} style={{borderBottomLeftRadius: 8, borderBottomRightRadius: 8, borderTopLeftRadius: 8, borderTopRightRadius: 8}}/>)}</motion.div></Transition>
</motion.div>
</LayoutGroup>)

});

const css = [".framer-sTaNq [data-border=\"true\"]::after { content: \"\"; border-width: var(--border-top-width, 0) var(--border-right-width, 0) var(--border-bottom-width, 0) var(--border-left-width, 0); border-color: var(--border-color, none); border-style: var(--border-style, none); width: 100%; height: 100%; position: absolute; box-sizing: border-box; left: 0; top: 0; border-radius: inherit; pointer-events: none; }", "@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-sTaNq .framer-1xz74u3 { display: block; }", ".framer-sTaNq .framer-1frf5y5 { align-content: flex-start; align-items: flex-start; cursor: pointer; display: flex; flex-direction: row; flex-wrap: nowrap; gap: 10px; height: 200px; justify-content: flex-end; padding: 8px 8px 8px 8px; position: relative; width: 200px; }", ".framer-sTaNq .framer-10ps6sl { aspect-ratio: 1 / 1; flex: 1 0 0px; height: var(--framer-aspect-ratio-supported, 184px); overflow: visible; position: relative; width: 1px; }", "@supports (background: -webkit-named-image(i)) and (not (font-palette:dark)) { .framer-sTaNq .framer-1frf5y5 { gap: 0px; } .framer-sTaNq .framer-1frf5y5 > * { margin: 0px; margin-left: calc(10px / 2); margin-right: calc(10px / 2); } .framer-sTaNq .framer-1frf5y5 > :first-child { margin-left: 0px; } .framer-sTaNq .framer-1frf5y5 > :last-child { margin-right: 0px; } }", ".framer-sTaNq.framer-v-13xrkg0 .framer-1frf5y5 { aspect-ratio: 1 / 1; height: var(--framer-aspect-ratio-supported, 200px); }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 200
 * @framerIntrinsicWidth 200
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","fixed"]},"J7T83zccY":{"layout":["fixed","fixed"]}}}
 * @framerVariables {"xRALwOhFA":"cover","Hsz0D4R48":"tap"}
 * @framerImmutableVariables false
 */
const FramerCYZzSgT0A: React.ComponentType<Props> = withCSS(Component, css, "framer-sTaNq") as typeof Component;
export default FramerCYZzSgT0A;

FramerCYZzSgT0A.displayName = "CMS/Image";

FramerCYZzSgT0A.defaultProps = {height: 200, width: 200};

addPropertyControls(FramerCYZzSgT0A, {variant: {options: ["m7TdykRJN", "J7T83zccY"], optionTitles: ["Default", "Selected"], title: "Variant", type: ControlType.Enum}, xRALwOhFA: {title: "Cover", type: ControlType.ResponsiveImage}, Hsz0D4R48: {title: "Tap", type: ControlType.EventHandler}} as any)

addFonts(FramerCYZzSgT0A, [])